import 'App.css';

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import loadable from '@loadable/component';
import { Auth as AmplifyAuth } from 'aws-amplify';
import React, { useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import getIn from 'unmutable/lib/getIn';
import { ThemeProvider } from 'styled-components';

import Loader from 'style/status/Loader';
import { ThemeContext } from 'style/themes/themeContext';
import { themeReducer, getInitialState } from 'style/themes/themeReducer';
import { CHANGE_THEME } from 'style/themes/types';
import Auth from 'components/Auth';

// Views
const LoadableGetStarted = loadable(() => import('views/get-started'), { fallback: <Loader /> });
const LoadableHome = loadable(() => import('views/home'), { fallback: <Loader /> });

const REACT_APP_API_HOSTNAME = process.env.REACT_APP_API_HOSTNAME || '';

const httpLink = createHttpLink({
    uri: REACT_APP_API_HOSTNAME,
});

const authLink = setContext(async (_, { headers }) => {
    const cognitoUser = await AmplifyAuth.currentAuthenticatedUser();
    const token: string | null = cognitoUser ? getIn(['signInUserSession', 'idToken', 'jwtToken'])(cognitoUser) : null;

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.REACT_APP_STAGE === 'local' || process.env.REACT_APP_STAGE === 'alpha',
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App() {
    const [state, dispatch] = useReducer(themeReducer, getInitialState());

    const changeTheme = (nextTheme: Record<string, unknown>) => {
        dispatch({
            payload: nextTheme,
            type: CHANGE_THEME,
        });
    };

    return (
        <>
            <ThemeContext.Provider value={{ theme: state.theme, changeTheme }}>
                <ThemeProvider theme={state.theme}>
                    <ApolloProvider client={client}>
                        <Router>
                            <Auth>
                                <Switch>
                                    <Route path="/get-started">
                                        <LoadableGetStarted />
                                    </Route>
                                    <Route path="/">
                                        <LoadableHome />
                                    </Route>
                                </Switch>
                            </Auth>
                        </Router>
                    </ApolloProvider>
                </ThemeProvider>
            </ThemeContext.Provider>
        </>
    );
}

export default App;
