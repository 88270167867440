import styled from 'styled-components/macro';
import { flexbox, FlexboxProps } from 'styled-system';

import Box, { IBoxProps } from './Box';

export interface IFlexBoxProps extends IBoxProps, FlexboxProps {}

const Flex = styled(Box)<IBoxProps>`
    ${flexbox}
`;

export default Flex;

Flex.defaultProps = {
    display: 'flex',
};
