import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';

declare global {
    interface Window {
        app: any;
        Sentry: any;
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

const REACT_APP_SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY || '';
const REACT_APP_STAGE = process.env.REACT_APP_STAGE || '';
const REACT_APP_GIT_SHA = process.env.REACT_APP_GIT_SHA || '';
const REACT_APP_BUILD_TIMESTAMP = process.env.REACT_APP_BUILD_TIMESTAMP || '';
const isProduction = REACT_APP_STAGE === 'live' || REACT_APP_STAGE === 'alpha';

/**
 * Load segment tracking code
 */
if (isProduction && REACT_APP_SEGMENT_KEY) {
    const el = document.getElementById('segment-tag');

    if (el) {
        el.innerText = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.REACT_APP_SEGMENT_KEY}";analytics.SNIPPET_VERSION="4.13.2";
        analytics.load("${REACT_APP_SEGMENT_KEY}");
        }}();
        `;
    }
}

/**
 * Set version in Sentry
 *
 * This is dodgy and will not work if Sentry is not initialised within 2 seconds
 */
if (isProduction && REACT_APP_GIT_SHA) {
    setTimeout(() => {
        if (window.Sentry) {
            window.Sentry.init({ release: REACT_APP_GIT_SHA });
        }
    }, 2000);
}

// App version details
const app = {
    version: REACT_APP_GIT_SHA,
    timestamp: REACT_APP_BUILD_TIMESTAMP,
};

window.app = app;
