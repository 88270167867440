import { setLocalCache, getLocalCache } from 'utils/localCache';

import LightTheme from './bf-light';
import { CHANGE_THEME } from './types';

interface ThemeState {
    theme: Record<string, unknown>;
}

interface ThemeAction {
    type: string;
    payload: Record<string, unknown>;
}

export const themeReducer = (state: ThemeState, action: ThemeAction): ThemeState => {
    switch (action.type) {
        case CHANGE_THEME:
            setLocalCache('theme', action.payload);

            return {
                ...state,
                theme: action.payload,
            };
        default:
            return state;
    }
};

export const getInitialState = (): ThemeState => ({
    theme: getLocalCache('theme') || LightTheme,
});
