import styled from 'styled-components/macro';
import { color, typography, ColorProps, TypographyProps } from 'styled-system';

export interface TextProps extends ColorProps, TypographyProps {}

const Text = styled.span.attrs((props: any) => props)<TextProps>`
    ${color}
    ${typography}
`;

export default Text;

Text.defaultProps = {
    color: 'text',
    fontFamily: 'text',
    fontSize: 3,
    lineHeight: 'text',
};
