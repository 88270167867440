/* eslint-disable import/prefer-default-export */
import { createContext } from 'react';

interface ThemeContextProps {
    changeTheme: (theme: Record<string, unknown>) => void;
    theme: Record<string, unknown>;
}

export const ThemeContext = createContext<ThemeContextProps>({
    // tslint:disable-next-line: no-empty
    changeTheme: () => {},
    theme: {},
});
