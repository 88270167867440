const theme: Record<string, unknown> = {
    breakpoints: ['415px', '769px', '1024px'],
    fontSizes: [12, 14, 16, 20, 24, 36, 48, 80, 96],
    fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
    fonts: {
        heading: "'Open Sans', serif",
        text: "'Open Sans', sans-serif",
        code: 'courier',
    },
    heights: [16, 32, 64, 128, 256],
    lineHeights: {
        heading: 1.3,
        text: 1.1,
    },
    maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
    radii: [0, 2, 4, 8, 16, 9999, '100%'],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    width: [16, 32, 64, 128, 256],
};

export default theme;
