// name it what the icon is, or name it what the icon represents
// do not name it what the icon DOES, unless the icon cannot be named anything else (e.g. "share" icon)
// naming an icon "cross" or "pencil" is good
// naming an icon "close" or "edit" is bad

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

// import {} from '@fortawesome/free-brands-svg-icons';
// import {} from '@fortawesome/free-regular-svg-icons';
import {
    faCog as fasCog,
    faCheckCircle as fasCheckCircle,
    faCircle as fasCircle,
    faExclamationCircle as fasExclamationCircle,
    faExclamationTriangle as fasExclamationTriangle,
    faEye as fasEye,
    faHome as fasHome,
    faSpinnerThird as fasSpinnerThird,
    faUser as fasUser,
} from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as farCheckCircle, faCircle as farCircle, faEllipsisH as farEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import {
    faArrowLeft as falArrowLeft,
    faBell as falBell,
    faCar as falCar,
    faCars as falCars,
    faChevronDown as falChevronDown,
    faChevronRight as falChevronRight,
    faCode as falCode,
    faCreditCard as falCreditCard,
    faDollarSign as falDollarSign,
    faGarageCar as falGarageCar,
    faIdCard as falIdCard,
    faKey as falKey,
    faLockAlt as falLockAlt,
    faPlug as falPlug,
    faStoreAlt as falStoreAlt,
    faTachometerAltFast as falTachometerAltFast,
    faTrashAlt as falTrashAlt,
    faUserFriends as falUserFriends,
} from '@fortawesome/pro-light-svg-icons';
// import {} from '@fortawesome/pro-duotone-svg-icons';

export type BFIconType = IconDefinition;

export interface BFIconLibraryProps {
    ArrowLeftLight: BFIconType;
    BellLight: BFIconType;
    CarLight: BFIconType;
    CarsLight: BFIconType;
    CarGarageLight: BFIconType;
    CheckCircleSolid: BFIconType;
    ChevronDownLight: BFIconType;
    ChevronRightLight: BFIconType;
    CircleSolid: BFIconType;
    CircleRegular: BFIconType;
    CircleCheckRegular: BFIconType;
    CodeLight: BFIconType;
    CogSolid: BFIconType;
    CreditCardLight: BFIconType;
    DollarSignLight: BFIconType;
    EllipsisRegular: BFIconType;
    ExclamationCircleSolid: BFIconType;
    ExclamationTriangleSolid: BFIconType;
    EyeSolid: BFIconType;
    HomeSolid: BFIconType;
    IdCardLight: BFIconType;
    KeyLight: BFIconType;
    LockAltLight: BFIconType;
    PlugLight: BFIconType;
    SpinnerThirdSolid: BFIconType;
    StoreAltLight: BFIconType;
    TachometerAltFastLight: BFIconType;
    TrashAltLight: BFIconType;
    UserFriendsLight: BFIconType;
    UserSolid: BFIconType;
}

export const IconLibrary: BFIconLibraryProps = {
    ArrowLeftLight: falArrowLeft,
    BellLight: falBell,
    CarLight: falCar,
    CarsLight: falCars,
    CarGarageLight: falGarageCar,
    CheckCircleSolid: fasCheckCircle,
    ChevronDownLight: falChevronDown,
    ChevronRightLight: falChevronRight,
    CircleRegular: farCircle,
    CircleSolid: fasCircle,
    CircleCheckRegular: farCheckCircle,
    CodeLight: falCode,
    CogSolid: fasCog,
    CreditCardLight: falCreditCard,
    DollarSignLight: falDollarSign,
    EllipsisRegular: farEllipsisH,
    ExclamationCircleSolid: fasExclamationCircle,
    ExclamationTriangleSolid: fasExclamationTriangle,
    EyeSolid: fasEye,
    HomeSolid: fasHome,
    IdCardLight: falIdCard,
    KeyLight: falKey,
    LockAltLight: falLockAlt,
    PlugLight: falPlug,
    SpinnerThirdSolid: fasSpinnerThird,
    StoreAltLight: falStoreAlt,
    TachometerAltFastLight: falTachometerAltFast,
    TrashAltLight: falTrashAlt,
    UserFriendsLight: falUserFriends,
    UserSolid: fasUser,
};
