/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Text, { TextProps } from 'style/typography/Text';

const Heading: React.FC<TextProps> = (props) => <Text {...props} />;

export default Heading;

Heading.defaultProps = {
    color: 'text-dark',
    fontFamily: 'heading',
    fontSize: 6,
    fontWeight: 3,
    lineHeight: 'heading',
};
