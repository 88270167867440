import styled from 'styled-components/macro';
import {
    background,
    border,
    color,
    flexbox,
    layout,
    position,
    space,
    typography,
    width,
    BackgroundProps,
    BorderProps,
    ColorProps,
    FlexboxProps,
    LayoutProps,
    PositionProps,
    SpaceProps,
    TypographyProps,
    WidthProps,
} from 'styled-system';

export interface IBoxProps extends BackgroundProps, BorderProps, ColorProps, LayoutProps, SpaceProps, WidthProps, TypographyProps, FlexboxProps, PositionProps {}

const Box = styled.div<IBoxProps>`
    ${background}
    ${border}
    ${color}
    ${flexbox}
    ${layout}
    ${position}
    ${space}
    ${typography}
    ${width}
`;

export default Box;
