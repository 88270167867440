import React from 'react';

import Box from 'style/layout/Box';
import Flex from 'style/layout/Flex';
import Heading from 'style/typography/Heading';
import Icon, { IconLibrary } from 'style/resources/Icon';

interface ILoader {
    color?: string;
    fullPage?: boolean;
    size?: number;
}

const Loader: React.FC<ILoader> = (props) => {
    const { children, fullPage, color, size } = props;

    const content = children || (
        <Heading fontSize={size} fontWeight={0}>
            Loading...
        </Heading>
    );

    if (fullPage) {
        return (
            <Flex alignItems="center" justifyContent="center" height="100%">
                <Box textAlign="center" color={color} fontSize={size}>
                    <Icon icon={IconLibrary.SpinnerThirdSolid} spin />
                    <Box mb={5}>{content}</Box>
                </Box>
            </Flex>
        );
    }

    return (
        <Box color={color} fontSize={size}>
            <Icon icon={IconLibrary.SpinnerThirdSolid} spin />
            <Box mb={5}>{content}</Box>
        </Box>
    );
};

export default Loader;

Loader.defaultProps = {
    fullPage: true,
    color: 'text',
    size: 5,
};
