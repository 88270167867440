// eslint-disable-next-line max-len
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const setLocalCache = (key: string, value: any): void => {
    if (localStorage && localStorage.setItem) {
        localStorage.setItem(key, JSON.stringify(value));
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLocalCache = (key: string): any => {
    if (localStorage && localStorage.getItem) {
        const cache = localStorage.getItem(key);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return cache ? JSON.parse(cache) : null;
    }

    return null;
};
