/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { color, ColorProps, fontSize, FontSizeProps } from 'styled-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BFIconType } from './IconLibrary';

export interface BFIconProps {
    icon: BFIconType;
    spin?: boolean;
    color?: string;
    fontSize?: number;
}

const StyledIcon = styled.span<ColorProps & FontSizeProps>`
    ${color}
    ${fontSize}
`;

const Icon: React.FC<BFIconProps> = (props) => {
    const { icon, spin } = props;

    const iconColor = props.color;
    const iconFontSize = props.fontSize;

    return (
        <StyledIcon color={iconColor} fontSize={iconFontSize}>
            <FontAwesomeIcon icon={icon} spin={spin} />
        </StyledIcon>
    );
};

export default Icon;
