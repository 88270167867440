// tslint:disable: object-literal-sort-keys

import baseTheme from './bf-base';

/**
 * Semantic Color
 * Our choice of colors are based on this document https://spectrum.adobe.com/page/color/#Semantic-colors
 *
 * TODO: To generate custom themes, consider using something like https://github.com/adobe/leonardo
 */

const baseColors: { [color: string]: string } = {
    white: '#ffffff',
    black: '#000000',

    'gray-50': '#f5f5f5',
    'gray-75': '#ebebeb',
    'gray-100': '#e2e2e2',
    'gray-200': '#cfcfcf',
    'gray-300': '#bbbbbb',
    'gray-400': '#a8a8a8',
    'gray-500': '#9f9f9f',
    'gray-600': '#7f7f7f',
    'gray-700': '#5f5f5f',
    'gray-800': '#3f3f3f',
    'gray-900': '#1f1f1f',

    'light-blue-500': '#00BEFF',
    'light-blue-700': '#0090c3',

    'dark-blue-500': '#002028',

    'green-500': '#239608',

    'orange-500': '#E98C00',

    'red-500': '#FF3C38',

    'purple-500': '#b400ff',
};

const color = (name: string): string => baseColors[name] || 'rebeccapurple';

const theme: Record<string, unknown> = {
    ...baseTheme,
    borders: [
        0,
        `1px solid ${color('gray-300')}`,
        `2px solid ${color('gray-300')}`,
        `4px solid ${color('gray-300')}`,
        `8px solid ${color('gray-300')}`,
        `16px solid ${color('gray-300')}`,
        `32px solid ${color('gray-300')}`,
    ],
    colors: {
        // Theme colour aliases
        // This is the naming convention to use
        // {name}-lighter
        // {name}-light
        // {name}
        // {name}-dark
        // {name}-darker

        ...baseColors,

        primary: color('light-blue-500'),
        'primary-dark': color('dark-blue-500'),

        'primary-hover': color('light-blue-700'),

        secondary: color('orange-500'),

        background: color('gray-100'),
        'background-dark': color('gray-100'),
        'background-darker': color('gray-200'),
        'background-light': color('gray-75'),
        'background-lighter': color('gray-50'),

        border: color('gray-300'),
        'border-dark': color('gray-400'),
        'border-darker': color('gray-500'),
        'border-light': color('gray-200'),
        'border-lighter': color('gray-100'),

        text: color('gray-800'),
        'text-dark': color('gray-900'),
        'text-disabled': color('gray-500'),
        'text-light': color('gray-700'),
        'text-lighter': color('gray-600'),

        informative: color('purple-500'),
        negative: color('red-500'),
        notice: color('orange-500'),
        positive: color('green-500'),
    },
};

export default theme;
