import styled from 'styled-components/macro';
import { background, border, color, space, typography, BackgroundProps, BorderProps, ColorProps, SpaceProps, TypographyProps } from 'styled-system';

export interface SpanProps extends BackgroundProps, BorderProps, ColorProps, SpaceProps, TypographyProps {}

const Span = styled.span<SpanProps>`
    ${background}
    ${border}
    ${color}
    ${space}
    ${typography}
`;

export default Span;

Span.defaultProps = {};
