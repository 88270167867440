import Amplify from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import getIn from 'unmutable/lib/getIn';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import Box from 'style/layout/Box';
import Flex from 'style/layout/Flex';
import logo from 'assets/blueflagLogo.png';
import Loader from 'style/status/Loader';
import Span from 'style/layout/Span';

const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION || '';
const REACT_APP_COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID || '';
const REACT_APP_COGNITO_USER_POOL_WEB_CLIENT = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT || '';

// tslint:disable: object-literal-sort-keys
Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: REACT_APP_COGNITO_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: REACT_APP_COGNITO_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_WEB_CLIENT,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: true,
    },
});
// tslint:enable: object-literal-sort-keys

const AuthWrapper: React.FC<{ children: React.ReactNode }> = (props) => {
    const { children } = props;
    const [authState, setAuthState] = useState<AuthState>();
    const [user, setUser] = useState<undefined | {}>();

    const [email, setEmail] = useState<undefined | string>();

    const history = useHistory();
    const pathname = (getIn(['location', 'pathname'])(history) || '') as string;

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData);
        });
    }, []);

    if (pathname.startsWith('/get-started')) {
        // public pages do not require users to be signed in
        return <>{children}</>;
    }
    if (authState === AuthState.SignedIn && user) {
        // authenticated
        return <>{children}</>;
    }

    const formFields = [
        {
            type: 'username',
            label: 'Email *',
            placeholder: 'Enter your email',
            required: true,
            value: email,
            handleInputChange: (ee: { [key: string]: any }) => {
                const nextEmail = ee?.target.value;

                setEmail(nextEmail);
            },
        },
        {
            type: 'password',
            required: true,
        },
    ];

    return (
        <Flex justifyContent="center" alignItems="center" height="100vh">
            {/* Initializing */}
            {!authState && (
                <Box>
                    <Loader />
                    <AmplifyAuthenticator usernameAlias="email">
                        <AmplifySignIn slot="sign-in" headerText="" usernameAlias="email" hideSignUp={false} />
                    </AmplifyAuthenticator>
                </Box>
            )}

            {/* Ready */}
            {authState && (
                <Box>
                    <Box py={4} maxWidth={250} textAlign="center" mx="auto">
                        <img src={logo} alt="Blue Flag Logo" />
                    </Box>
                    <Box>
                        <AmplifyAuthenticator usernameAlias="email">
                            <AmplifySignIn slot="sign-in" headerText="" usernameAlias="email" hideSignUp formFields={formFields} />
                        </AmplifyAuthenticator>
                    </Box>
                    <Box py={0} textAlign="center" mx="auto" fontSize={2} color="text-light">
                        <Span>Don&apos;t have an account? </Span>
                        <Span color="secondary">
                            <a href="https://blueflag.com.au/signup/" style={{ color: '#00BEFF', textDecoration: 'none' }}>
                                Sign Up
                            </a>
                        </Span>
                    </Box>
                </Box>
            )}
        </Flex>
    );
};

export default AuthWrapper;
